<template>
    <div class="page-switcher" v-click-outside:page-switcher__dropdown.stop="closeDropdown">
        <div class="page-switcher__header" @click="toggleDropdown">
            {{ pageTitle }}
            <span class="-down material-icons-outlined">arrow_drop_down</span>
        </div>
        <div class="page-switcher__dropdown" :class="{ 'is-open': isDropdownShow }">
            <progress-linear v-if="isLoading"></progress-linear>
            <div class="page-switcher__search" :class="{ 'is-show': isSearchShow }">
                <PageSwitcherSearch v-model="search" />
            </div>
            <div class="page-switcher_list">
                <PageSwitcherList
                    :items="areNestedItemsShown ? nestedItems : items"
                    :type="currentType"
                    :search="search"
                    @open-nested="getNestedItems"
                    @switch-page="switchPage"
                    @select-type="selectType"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { changeRoute } from '@/services/builderService';
import PageSwitcherList from '@/components/builder/topbar/page-switcher/list.vue';
import PageSwitcherSearch from '@/components/builder/topbar/page-switcher/search.vue';
import PageService from '@/services/PageService.ts';
import ProductService from '@/services/ProductService.ts';
import CatalogService from '@/services/CatalogService.ts';
import ArticleService from '@/services/ArticleService';
import CategoryService from '@/services/CategoryService';
import ProgressLinear from '@/components/common/ProgressLinear';

export default {
    name: 'PageSwitcher',
    components: {
        PageSwitcherList,
        PageSwitcherSearch,
        ProgressLinear,
    },
    async created() {
        const data = await this.loadData();
        this.items = [
            {
                id: 'homepage',
                type: 'homepage',
                title: 'builder.pageType.homepage',
                icon: 'home',
            },
            {
                id: 'textpage',
                type: 'textpage',
                title: 'builder.pageType.textPages',
                icon: 'article',
                items: [
                    ...data.pages,
                    {
                        id: '00f03bf5-7a333-4d354-a475-aefdcbd3593b9b',
                        title: 'builder.topbar.pageSwitcher.addNew',
                        type: 'textpage',
                        icon: 'add_circle',
                        class: '-highlighted',
                        action: 'create',
                    },
                ],
            },
            {
                id: 'catalog',
                type: 'catalog',
                title: 'builder.pageType.catalogs',
                icon: 'shopping_bag',
                items: [
                    ...data.catalogs,
                    {
                        id: '00f03bf5-7a33-4d354-a475-aedcbd3593b9b',
                        title: 'builder.topbar.pageSwitcher.addNew',
                        type: 'catalog',
                        icon: 'add_circle',
                        class: '-highlighted',
                        action: 'create',
                    },
                ],
            },
            {
                ...{
                    id: data?.product?.id,
                    slug: data?.product?.slug,
                },
                title: 'builder.pageType.product',
                type: 'product',
                icon: 'inventory_2',
            },
            {
                id: 'category',
                type: 'category',
                title: 'builder.pageType.category',
                icon: 'feed',
                items: [...data.category],
            },
            {
                id: 'checkout',
                type: 'checkout',
                title: 'builder.pageType.checkout',
                icon: 'add_shopping_cart',
            },
            {
                id: 'cart',
                type: 'cart',
                title: 'builder.pageType.cart',
                icon: 'shopping_cart',
            },
        ];
    },
    data() {
        return {
            isDropdownShow: false,
            isSearchShow: false,
            items: [],
            currentType: null,
            nestedItems: [],
            search: '',
            nestedItemsHelpers: {
                category: {
                    childrenService: ArticleService,
                    idsField: null,
                    idsSourceArray: null,
                    getItemsByParent: true,
                    childrenType: 'article',
                },
                article: {
                    parentType: 'category',
                },
            },
            isLoading: false,
        };
    },
    computed: {
        ...mapState('builder', {
            currentPage: 'currentPage',
        }),
        pageTitle() {
            if (this.currentPage) {
                if (this.currentPage.type === 'homepage') return this.$t('builder.pageType.homepage');
                if (this.currentPage.type === 'product') return this.$t('builder.pageType.product');
                return this.currentPage.title;
            } else return this.$t('builder.pageType.' + this.$route.params.type);
        },
        areNestedItemsShown() {
            return !!this.nestedItems.length;
        },
    },
    methods: {
        async loadData() {
            const responses = await Promise.all([
                await PageService.getAll(),
                await CatalogService.getAll(),
                await ProductService.getAll({ limit: 1 }),
                await CategoryService.getAll(),
            ]);
            const error = responses.find((response) => {
                return response[0];
            });
            if (error) {
                error.alert();
                return {};
            }
            const data = responses.map((response) => response[1]);
            return {
                pages: data[0]
                    .map((item) => ({ ...item, type: 'textpage' }))
                    .sort((a, b) => a.title.localeCompare(b.title)),
                catalogs: data[1]
                    .map((item) => ({ ...item, type: 'catalog' }))
                    .sort((a, b) => a.title.localeCompare(b.title)),
                product: data[2].products[0],
                category: data[3]
                    .map((item) => ({ ...item, type: 'category', nested: true }))
                    .sort((a, b) => a.title.localeCompare(b.title)),
            };
        },
        toggleDropdown() {
            this.isDropdownShow = !this.isDropdownShow;
        },
        closeDropdown() {
            this.isDropdownShow = false;
        },
        switchPage(item) {
            this.isDropdownShow = !this.isDropdownShow;
            if (item.action === 'create') {
                this.$router.push({
                    name: 'Create',
                    params: {
                        type: item.type,
                    },
                });
            } else {
                if (item?.type && item?.id) {
                    changeRoute.call(this, item);
                    this.menuState = false;
                }
            }
        },
        selectType(type) {
            if (!type && this.areNestedItemsShown) {
                this.currentType = this.nestedItemsHelpers[this.currentType].parentType;
                this.nestedItems = [];
            } else {
                this.currentType = type;
            }
            this.isSearchShow = this.currentType ? true : false;
        },
        async getNestedItems(parent) {
            this.isLoading = true;
            const helper = this.nestedItemsHelpers[parent.type];
            if (helper) {
                let error, result;
                if (helper.getItemsByParent) {
                    [error, result] = await helper.childrenService.getAll({ [parent.type]: parent.id });
                } else {
                    const ids = helper.idsSourceArray
                        ? parent[helper.idsSourceArray]?.map((item) => item[helper.idsField])
                        : parent[helper.idsField];
                    const childrenService = helper.childrenService;
                    [error, result] = await childrenService.getAll({ ids });
                }
                if (error) {
                    error.notify();
                    this.nestedItems = [];
                }
                this.nestedItems.push({
                    id: helper.childrenType,
                    type: helper.childrenType,
                    icon: 'feed',
                    title: `builder.pageType.${helper.childrenType}`,
                    titleParams: { [parent.type]: parent.title },
                    items: result.map((item) => {
                        return {
                            ...item,
                            type: helper.childrenType,
                        };
                    }),
                });
                this.selectType(helper.childrenType);
            }
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/mixins';

.page-switcher {
    position: relative;
    max-width: 340px;
    width: 100%;

    @media screen and (min-width: 1550px) {
        max-width: 406px;
    }

    &__header {
        position: relative;
        height: $input-height;
        padding: 0 40px 0 12px;
        background: #fff;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        transition: $input-transition;
        line-height: $input-height - 2px;
        cursor: pointer;
        font-size: 16px;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            border-color: $input-border-color-hover;
        }

        .material-icons-outlined.-down {
            position: absolute;
            top: 9px;
            right: 12px;
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: $input-height;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        .progress-linear {
            top: 0;
            height: 2px;
            border-radius: 0 0 4px 4px;
        }
    }

    &_list {
        max-height: 50vh;
        overflow: auto;
    }

    &__search {
        width: 100%;
        opacity: 0;
        margin: -52px 0 0;
        pointer-events: none;
        transition: $transition-fast;

        &.is-show {
            opacity: 1;
            margin: 0;
            pointer-events: auto;
        }
    }
}
</style>
